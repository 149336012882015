import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DownloadCard from "../components/cards/downloadCard"

const RenderBody = ({ socialCard, downloads, meta }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={`Free Downloads | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: `${meta.description}`,
        },
        {
          property: `og:url`,
          content: `${meta.url}/free-downloads`,
        },
        {
          property: `og:title`,
          content: "Free Downloads",
        },
        {
          property: `og:description`,
          content: `${meta.description}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: `@tivp_org`,
        },
        {
          name: `twitter:site`,
          content: `@tivp_org`,
        },
        {
          name: `twitter:title`,
          content: "Free Downloads",
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${meta.url}",
                "name": "${meta.title}",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "email": "info@theinfinitevoiceproject.org",
                    "contactType": "inquiries"
                }
            }
        `}
      </script>
    </Helmet>
    <div className="w-full flex flex-col justify-center items-center px-8 desktop:px-0 min-h-full bg-gray-100 py-24">
      <div className="w-full laptop:w-2/3 desktop:w-4/5">
        <div className="w-full mb-12">
          <h1>Free Downloads</h1>
        </div>
        <div className="w-full">
          <h2>Coloring Pages</h2>
        </div>
        <div className="w-full flex flex-col justify-center content-center items-center">
          <div className="w-full grid grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-3 desktop:grid-cols-4 gap-x-12 gap-y-16">
            {downloads.map((download, i) => (
              <DownloadCard
                key={i}
                id={download.id}
                cover={download.node.file.gatsbyImageData}
                url={download.node.file.url}
                alt={download.node.title}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
)

const FreeDownloads = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const socialCard = data.card
  const downloads = data.allGraphCmsFreeDownload.edges

  if (!downloads) return null

  return (
    <Layout>
      <RenderBody downloads={downloads} meta={meta} socialCard={socialCard} />
    </Layout>
  )
}

export default FreeDownloads

RenderBody.propTypes = {
  downloads: PropTypes.array.isRequired,
  meta: PropTypes.array.isRequired,
}

export const query = graphql`
  query FreeDownloads {
    allGraphCmsFreeDownload {
      edges {
        node {
          title
          id
          file {
            url
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    card:file(name: {eq: "SocialCardTemplate-Free-Downloads"}) {
      id
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
  }
`
