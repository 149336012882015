import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import DownloadButton from "../buttons/downloadButton"

const DownloadCard = ({ cover, url, id, title }) => (
  <div className="h-full phone:w-full w-80">
    <div className="phone:w-full w-80 flex flex-col h-96" key={id}>
      <div className="phone:w-full w-80 h-96">
        <GatsbyImage
          image={cover}
          className="h-full w-full laptop:h-128 laptop:w-96 object-contain shadow-xl"
          alt={title}
        />
      </div>
    </div>
    <div className="phone:w-full w-80 pt-12">
      <a
        download
        href={url}
        target="_blank"
        rel="noreferrer"
        referrerpoilicy="strict-origin-when-cross-origin"
      >
        <DownloadButton />
      </a>
    </div>
  </div>
)

export default DownloadCard

DownloadCard.propTypes = {
  cover: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}
