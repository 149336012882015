import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"

export default function DownloadButton() {
  return (
    <button
      className="w-full font-oswald text-lg tablet:text-xl bg-gradient-to-b from-green-500 to-green-700
       border border-green-900 shadow-md shadow-gray-900/80 text-white rounded p-2"
    >
      <div className="text-white font-semibold">
        <FontAwesomeIcon
          icon={faCloudDownloadAlt}
          size="lg"
          color="current"
          className="mr-2"
        />
        Download
      </div>
    </button>
  )
}
